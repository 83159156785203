import styles from "./Experience.module.css"
import dial from '../images/dial.svg'
import ProgressChart from "./UI/ProgressChart";

const Experience = () => {
    return <section className={styles.experience} id='experience'>
        <img src={dial}/>
        <div className={styles.data}>
            <div className={styles.header}>
                Experience
            </div>
            <div className={styles.chartTrack}>
                <ProgressChart className={styles.progressChart} label="Rennovex" color="#9004FF" value={.5}/>
                <ProgressChart className={styles.progressChart} style={{marginLeft:20}} label="React JS Dev" color="#836CEC" value={.7}/>
                <ProgressChart className={styles.progressChart} style={{marginLeft:35}} label="Node JS Dev" color="#5998F2" value={.5}/>
                <ProgressChart className={styles.progressChart} style={{marginLeft:20}} label="Flutter Dev" color="#37BBF7" value={.3}/>
                <ProgressChart className={styles.progressChart} label="UI Designer" color="#1AD8FC" value={.5}/>
            </div>
        </div>
    </section>
}

export default Experience;