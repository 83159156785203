import styles from './Footer.module.css'
import mail from '../images/mail.svg'

import instagram from '../images/instagram.svg'
import facebook from '../images/facebook.svg'
import github from '../images/github.svg'
import twitter from '../images/twitter.svg'

const Footer = () => {
    return <section className={styles.footer} id='footer'>
        <div className={styles.header}>
            Let's work together
        </div>
        <div className={styles.description}>
            I'd love to work with you to help you implement your ideas into code. Hit the button below and drop me a text
        </div>
        <div className={styles.header2}>
        Have an interesting project to work on? Mail me
        </div>
        <button className={styles.mailButton} onClick={()=>window.open('mailto:rohananilpnr@gmail.com?subject=Hey! I\'d like to work with you')}>
            <img src={mail}/>
            <div className={styles.mailButtonText}>
                rohananilpnr@gmail.com
            </div>
        </button>

        <div className={styles.socials}>
            <img src={instagram} onClick={()=>window.open('https://www.instagram.com/rohan_anilkumar/')}/>
            <img src={facebook} onClick={()=>window.open('https://www.facebook.com/rohan.anilkumar.754')}/>
            <img src={github} onClick={()=>window.open('https://github.com/rohananilkumar')}/>
            <img src={twitter} onClick={()=>window.open('https://twitter.com/anik_rohan')}/>
        </div>
    </section>
}

export default Footer;