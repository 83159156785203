import styles from "./Hero.module.css"
import Nav from "./Nav";
import rohan from '../images/Rohan.png';
import FadeIn from "react-fade-in/lib/FadeIn";
import BorderButton from "./UI/BorderButton";
import resume from '../assets/ROHAN.pdf'

const Hero = () => {
    return <section className={styles.hero} id='hero'>
        <Nav/>
        <div className={styles.heroData}>
            <div className={styles.heroMainText}>
                <FadeIn>
                    <div className={styles.rohanHeader}>
                        Rohan Anil Kumar,
                    </div>
                </FadeIn>
                
                <FadeIn>
                    <div className={styles.developerHeader}>
                    Software Developer 
                    </div>
                </FadeIn>
                
                <FadeIn>
                    <div className={styles.designerHeader}>
                    & Designer
                    </div>
                </FadeIn>
                
                <FadeIn>
                    <div className={styles.heroDescription}>
                        Turning ideas into software since 2015
                    </div>
                </FadeIn>

                <BorderButton color='#FD92FF' inactiveColor='black' onClick={()=>window.open(resume)}>
                    Resume
                </BorderButton>
            </div>
            <div className={styles.rohanImage} >
                <img src={rohan}/>
            </div>
            
        </div>
       
    </section>
}

export default Hero;