import styles from './Modal.module.css'
import placeholder from '../images/placeholder.png';
import Pill from './UI/Pill';
import github from '../icons/github-white.svg';
import web from "../icons/web-white.svg";
import FadeIn from "react-fade-in/lib/FadeIn";




const Modal = (props) => {
    return <section className={styles.backdrop}>
        <FadeIn >

        <div className={styles.modal}>
            <img src={placeholder}/>
            <div className={styles.modalData}>
                <div className={styles.written}>
                    <div className={styles.header}>
                        {props.project.header}
                    </div>
                    <div className={styles.description}>
                        {props.project.description}
                    </div>
                </div>
                <div className={styles.controls}>
                <div className={styles.pillTrack}>
                    {props.project.tech.map(tech=>{
                        return <Pill>
                            {tech}
                        </Pill>
                    })}
                </div>
                <div className={styles.buttonTrack}>
                    <div className={styles.grayButtonTrack}>
                    <button className={styles.grayButton} onClick={()=> props.project.source && window.open(props.project.source)}>
                        <img src={github}/>
                        <div className={styles.buttonText}>
                            Source
                        </div>
                    </button>
                    {props.project.site != '/' &&<button className={styles.grayButton} onClick={()=>props.project.link && window.open(props.project.link)}>
                        <img src={web}/>
                        <div className={styles.buttonText}>
                            Link
                        </div>
                    </button>}
                    </div>
                     <button className={styles.gradientButton} onClick={props.onBackClick}>
                        Go Back
                    </button>
                    
                </div>
                </div>
            </div>
        </div>|
        </FadeIn>

    </section>
}

export default Modal;