import styles from './ProgressChart.module.css';

const ProgressChart = (props) => {
    return <div {...props} className={styles.chart+ ' ' +props.className} >
    <div className={styles.chartLabel}>
        {props.label}
    </div>
    <div className={styles.chartGraph}>
        <div className={styles.chartFill} style={{width:props.value*100+'%', backgroundColor:props.color}}>
        </div>
    </div>
</div>
}

export default ProgressChart;