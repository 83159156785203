import { useState } from 'react';
import styles from './BorderButton.module.css'
const BorderButton = props => {
    const [hover,setHover] = useState(false);
    return <button {...props}
        onMouseEnter={()=>setHover(true)} 
        onMouseLeave={()=>setHover(false)} 
        className={styles.button} 
        style={{borderColor:hover?props.inactiveColor:props.color, color:hover?props.inactiveColor:props.color,backgroundColor:hover?props.color:props.inactiveColor}}>
        {props.children}
    </button>
}

export default BorderButton;