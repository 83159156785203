import Card from './UI/Card';
import styles from './Work.module.css';
import placeholder from '../images/placeholder.png';
import BorderButton from './UI/BorderButton';
import { useState } from 'react';
import reactDom from 'react-dom';
import Modal from './Modal';

class Project{
    constructor(header,description, image, tech, source, site){
        this.header = header;
        this.description = description;
        this.image = image;
        this.tech = tech;
        this.source = source;
        this.site = site;
    }
}







const projects = [
    new Project("Custom JSON Parser", "A C# class library for parsing complex json objects.",placeholder, ['C#','Class Library'], 'https://github.com/rohananilkumar/JsonParser', '/'),
    new Project("The Media Stream", "A server client software which can save and retrieve files from the server.",placeholder, ['Python'], 'https://github.com/rohananilkumar/TheMediaStream', '/'),
    new Project('Digitialize Porfolio', 'A store where you can buy portfolios. Source is classified. (by far my best looking website)',placeholder, ['ReactJS', 'Redux', 'Firebase'],'/' ,'https://digitialize-portfolio.web.app/home/pre-designed'),
    new Project("Memory Game", "The good old memory game where one has to eliminate all cards by choosing two idendical cards at the same time.",placeholder, ['JavaScript'], 'https://github.com/rohananilkumar/MemoryGame', '/'),
    new Project("Snake Game", "Snake game that used to be pre-installed in old nokia phones got a new look.",placeholder, ['JavaScript'], 'https://github.com/rohananilkumar/SnakeGame', '/'),
    new Project("Budgety", "Budget Calculator and organiser. Note that the UI is not designed by Rohan and is not optimised for mobile.",placeholder, ['JavaScript'], 'https://github.com/rohananilkumar/Budgety', '/'),
    new Project("Library Manager", "Library Manager for schools. Manages books and students. Uses MySQL as database.",placeholder, ['Python','Node','MySQL', 'RDBMS'], 'https://github.com/rohananilkumar/LibraryManager', '/'),
    new Project("File Encryptor", "File Encryptor can encrypt/decrypt files and folers using AES encryption.",placeholder, ['Python','Node','MySQL', 'RDBMS'], 'https://github.com/rohananilkumar/CryptoFileEncryptor', '/'),
    new Project("Weather Analyser", "11th Grade Science project. Helps in reporting harsh weather conditions. Collaborated with Karthik Krishnan",placeholder, ['Python','Node','MySQL', 'RDBMS'], 'https://github.com/rohananilkumar/WeatherAnalyser', '/'),
    new Project("Smart Home", "10th grade science project. Can control everyting from lights to doors using software.",placeholder, ['Python','Node','MySQL', 'RDBMS'], 'https://github.com/rohananilkumar/SmartHome', '/'),
    new Project("Password Encryptor", "Encrypts Password are returns the encrypted string which can then be decypted using a key.",placeholder, ['C#'], 'https://github.com/rohananilkumar/PasswordEncryptor', '/'),
    new Project("Portfolio Website", "This site.",placeholder, ['JavaScript', 'ReactJS'], 'https://github.com/rohananilkumar/rohan-anil-kumar.github.io', '/'),
    new Project("Infix Postfix Evaluator", "Program to convert infix expressions to postfix and hence evaluate the postfix expression.",placeholder, ['JavaScript'], 'https://github.com/rohananilkumar/InfixPostfix', '/'),
    new Project("FragDef", "Program to fragment and defragment files.",placeholder, ['Python'], 'https://github.com/rohananilkumar/FragDef', '/'),
    new Project('Vidly', 'Asp.NET web application for video rental services. Note that this is a practice project.',placeholder, ['ASP.NET', 'JQuery'],'https://github.com/rohananilkumar/vidly' ,'/'),
    new Project('Contact Book', 'Simple Contact Book application that performs all CRUD operations on contacts.',placeholder, ['C#','Xamarin', 'Android', 'SQLite'],'https://github.com/rohananilkumar/ContactBook' ,'/'),
    new Project('Flash Chat', 'Chat room application using flutter, powered by firebase auth and cloud firestore',placeholder, ['Flutter','Dart', 'Firebase'],'https://github.com/rohananilkumar/flash-chat' ,'/'),
    new Project('Clima', 'Android app that helps you get the weather forecast of your location',placeholder, ['Flutter','Dart'],'https://github.com/rohananilkumar/clima' ,'/'),
    new Project('BMI Calculator', 'Android app to calculate BMI that implements an elegant UI',placeholder, ['Flutter','Dart'],'https://github.com/rohananilkumar/bmi-calculator' ,'/'),
    new Project('Vidly NodeJS', 'NodeJS endpoint for vidly, a video rental service',placeholder, ['NodeJS', 'Express', 'MongoDB'],'https://github.com/rohananilkumar/vidly-nodejs' ,'/'),
    new Project('reserveStaurant', 'A restaurant app (nodejs Endpoints) that helps to get menu, order and pay without a waiter.',placeholder, ['NodeJS', 'Express', 'MongoDB'],'https://github.com/rohananilkumar/reservStaurant-endpoints' ,'/'),
    new Project("React Meals", "React app that simulates ordering food. This is a practice project",placeholder, ['ReactJS', 'Redux', 'Firebase'], 'https://github.com/rohananilkumar/react-meals', 'https://food-order-app-817a3.web.app/'),
    new Project("Great Quotes", "A simple app that can add and view quotes. This is a practice project",placeholder, ['ReactJS', 'Redux', 'Firebase'], 'https://github.com/rohananilkumar/great-quotes-react', 'https://great-quotes-app.web.app/quotes'),
]

const Work = () => {

    const [fullView, setFullView] =  useState(false);
    const [clickedProject, setClickedProject] = useState();

    const seeMoreClickHandler = () => {
        setFullView(state=>!state);
    }

    const cardClickHandler = (project) => {
        setClickedProject(project)
    }
    
    const backClickHandler = () => {
        setClickedProject(null);
    }

    return <section className={styles.work} id='work'>
        {clickedProject && reactDom.createPortal(<Modal project={clickedProject} onBackClick={backClickHandler}/>, document.getElementById('modal'))}
        <div className={styles.headerContainer}>
            <div className={styles.header}>Prominent</div>
            <div className={styles.headerGradient}>Works</div>
        </div>
        <div className={styles.projectsTrack}>
            {fullView && projects.map(project=><Card onClick={()=>cardClickHandler(project)} image={project.image} header={project.header} description={project.description}/>)}
           {!fullView && projects.slice(0,3).map(project=><Card onClick={()=>cardClickHandler(project)} image={project.image} header={project.header} description={project.description}/>)}
        </div>
        <div className={styles.button}>
            <BorderButton onClick={seeMoreClickHandler} color='#05EDFF' inactiveColor='black'>
                {fullView && "See Less"}
                {!fullView && "See More"}
            </BorderButton>
        </div>
    </section>
}

export default Work;