import styles from './Nav.module.css'
import { Link } from 'react-scroll';
const Nav =  () => {
    return <nav className={styles.nav} id='nav'>
        <ul className={styles.navList}>
        <li><Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            >About</Link></li>
            <li><Link
            activeClass="active"
            to="work"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            >Work</Link></li>
            <li><Link
            activeClass="active"
            to="experience"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            >Experience</Link></li>
            <li><Link
            activeClass="active"
            to="footer"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            >Contact</Link></li>
         { /*<ul onClick={()=>gsap.to(window, {duration: 2, scrollTo:"#about"})}>About</ul>
          <ul onClick={()=>gsap.to(window, {duration: 2, scrollTo:"#work"})}>Work</ul>
          <ul onClick={()=>gsap.to(window, {duration: 2, scrollTo:"#experience"})}>Experience</ul>
<ul onClick={()=>gsap.to(window, {duration: 2, scrollTo:"#contact"})}>Contact</ul>*/}
        </ul>
    </nav>
}

export default Nav;