import styles from "./About.module.css";
import monitor from "../icons/monitor.svg";
import mobile from "../icons/mobile.svg";
import paint from "../icons/paint.svg";
import server from "../icons/server.svg";
import web from "../icons/web.svg";

class Skill {
  constructor(highlighted, text, image) {
    this.highlighted = highlighted;
    this.text = text;
    this.image = image;
  }
}

const skills = [
  new Skill("Front End", "Development", monitor),
  new Skill("Back End API", "Development", server),
  new Skill("Android App", "Development", mobile),
  new Skill("User Interface", "Development", paint),
  new Skill("AWS cloud", "Managemement", web),
];

const About = () => {
  return (
    <section className={styles.about} id='about'>
      <div className={styles.skillList}>
        {skills.map((skill) => {
          return (
            <div className={styles.skill}>
              <img className={styles.skillImage} src={skill.image} />
              <div className={styles.skillTextHighlighted}>
                {skill.highlighted}
              </div>
              <div className={styles.skillText}>{skill.text}</div>
            </div>
          );
        })}
        
      </div>
      <div className={styles.header}>
        <div className={styles.headerText}>Turning Ideas</div>
        <div className={styles.headerTextGradient}>into code</div>
        <div className={styles.headerText}>is my thing</div>
      </div>
    </section>
  );
};

export default About;
