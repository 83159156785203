import logo from './logo.svg';
import './App.css';
import Nav from './components/Nav';
import Hero from './components/Hero';
import About from './components/About';
import Work from './components/Work';
import Experience from './components/Experience';
import Footer from './components/Footer';
import Modal from './components/Modal';

function App() {
  return (
    <div className="App" style={{overflow:'hidden'}}>
      <Hero/>
      <About/>
      <Work/>
      <Experience/>
      <Footer/>
    </div>
  );
}

export default App;
