import styles from "./Card.module.css";

const Card = (props) => {
  return (
    <div {...props} className={styles.card}>
      <img src={props.image} />
      <div className={styles.header}>
          {props.header}
      </div>
      <div className={styles.description}>
          {props.description}
      </div>
    </div>
  );
};

export default Card;
